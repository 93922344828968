import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { setAuthToken, setAuthUser } from '../../../redux/reducers/authSlice';
import { serverUrl } from '../../../config';
import Typography from '@mui/material/Typography';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('El email debe ser válido').required('El email es un campo obligatorio'),
    password: Yup.string().required('La contraseña es un campo obligatorio'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('email', values.email);
    formData.append('password', values.password);

    const res = await axios.post(`${serverUrl}/user-api/auth/signin`, Object.fromEntries(formData)).catch((e) => {
      e && e.response && e.response.data ? setError(e.response.data.message) : setError('El servidor no responde');
    });

    const user = res.data;
    const { role, authToken } = user;
    if (role !== 'ADMIN') {
      setError('Esta no es una cuenta de administrador');
      return;
    }
    dispatch(setAuthUser(user));
    dispatch(setAuthToken(authToken));
    navigate('/dashboard/app');
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email" />

        <RHFTextField
          name="password"
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton sx={{ mt: 3 }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Inicia sesión
      </LoadingButton>
      <Typography fontWeight={'bold'} color="red" align="center" sx={{ mt: 3 }}>
        {error}
      </Typography>
    </FormProvider>
  );
}
