import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Divider, Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken, setAuthUser } from '../../../redux/reducers/authSlice';
import Iconify from '../../../components/Iconify';
import { authApiCall } from '../../../services/http';
import { endpoints } from '../../../config';
import { subscriptionPlans } from 'src/helpers/userPlans';

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  user: PropTypes.any,
  onMenuAction: PropTypes.func,
};

export default function UserMoreMenu({ user, onMenuAction }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.authToken);

  const authApiCallHookProps = {
    navigate,
    dispatch,
    setAuthUser,
    setAuthToken,
    authToken,
    redirectRoute: null,
  };

  const makeUserAdmin = async () =>
    authApiCall({
      method: 'PUT',
      endpoint: endpoints.users,
      path: `/make-admin/${user.id}`,
      ...authApiCallHookProps,
    });

  const undoMakeUserAdmin = async () =>
    authApiCall({
      method: 'PUT',
      endpoint: endpoints.users,
      path: `/undo-make-admin/${user.id}`,
      ...authApiCallHookProps,
    });

  const deleteUser = async () =>
    authApiCall({
      method: 'DELETE',
      endpoint: endpoints.users,
      path: `/${user.id}`,
      ...authApiCallHookProps,
    });

  const updateUserPlan = async (planName) => {
    return authApiCall({
      method: 'PUT',
      endpoint: endpoints.users,
      path: `/update-plan/${user.id}`,
      data: {
        planName,
      },
      ...authApiCallHookProps,
    });
  };

  const updateWorkspacePlan = async (planName) => {
    return authApiCall({
      method: 'PUT',
      endpoint: endpoints.workspaces,
      path: `/update-plan/${user.workspace.id}`,
      data: {
        planName,
      },
      ...authApiCallHookProps,
    });
  };

  const alterUser = async (action, userId) => {
    try {
      if (action === 'make-admin') {
        await makeUserAdmin(userId);
        onMenuAction(action, userId);
      } else if (action === 'undo-make-admin') {
        await undoMakeUserAdmin(userId);
        onMenuAction(action, userId);
      } else if (action === 'delete') {
        const accept = window.confirm(
          `Esta acción no se puede deshacer, se eliminarán todos los recursos de ${user.givenName} (${user.email}) de la base de datos ¿Estás seguro?`
        );
        if (accept) {
          await deleteUser(userId);
          onMenuAction(action, userId);
        }
      } else if (action === 'show-metrics') {
        onMenuAction(action, userId);
      } else if (action === 'update-user-plan-free') {
        await updateUserPlan(subscriptionPlans.free.name);
        onMenuAction(action, userId);
      } else if (action === 'update-user-plan-premium') {
        await updateUserPlan(subscriptionPlans.premium.name);
        onMenuAction(action, userId);
      } else if (action === 'update-user-plan-unlimited') {
        await updateUserPlan(subscriptionPlans.unlimited.name);
        onMenuAction(action, userId);
      } else if (action === 'update-workspace-plan-free') {
        await updateWorkspacePlan(subscriptionPlans.free.name);
        onMenuAction(action, userId);
      } else if (action === 'update-workspace-plan-premium') {
        await updateWorkspacePlan(subscriptionPlans.premium.name);
        onMenuAction(action, userId);
      } else if (action === 'update-workspace-plan-unlimited') {
        await updateWorkspacePlan(subscriptionPlans.unlimited.name);
        onMenuAction(action, userId);
      }
    } catch (e) {
      console.error(e);
    }
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 300, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={async () => {
            await alterUser('show-metrics', user.id);
          }}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon="eva:activity-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Consultar métricas" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={async () => {
            await alterUser('make-admin', user.id);
          }}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon="eva:shield-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Hacer administrador" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await alterUser('undo-make-admin', user.id);
          }}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon="eva:undo-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deshacer administrador" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Workspace Plan */}
        {user.workspace.id && (
          <>
            <Divider />
            {Object.values(subscriptionPlans).find((subscriptionPlan) => subscriptionPlan.id === user.workspace.planId)
              .name !== subscriptionPlans.free.name && (
              <MenuItem
                onClick={async () => {
                  await alterUser('update-workspace-plan-free', user.id);
                }}
                component={RouterLink}
                to="#"
                sx={{ color: 'text.secondary' }}
              >
                <ListItemIcon>
                  <Iconify icon={'emojione-monotone:free-button'} width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={'Pasar Workspace a Free'} primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            )}
            {Object.values(subscriptionPlans).find((subscriptionPlan) => subscriptionPlan.id === user.workspace.planId)
              .name !== subscriptionPlans.premium.name && (
              <MenuItem
                onClick={async () => {
                  await alterUser('update-workspace-plan-premium', user.id);
                }}
                component={RouterLink}
                to="#"
                sx={{ color: 'text.secondary' }}
              >
                <ListItemIcon>
                  <Iconify icon={'mage:premier-pro'} width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={'Pasar Workspace a Pro'} primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            )}
            {Object.values(subscriptionPlans).find((subscriptionPlan) => subscriptionPlan.id === user.workspace.planId)
              .name !== subscriptionPlans.unlimited.name && (
              <MenuItem
                onClick={async () => {
                  await alterUser('update-workspace-plan-unlimited', user.id);
                }}
                component={RouterLink}
                to="#"
                sx={{ color: 'text.secondary' }}
              >
                <ListItemIcon>
                  <Iconify icon={'mdi:infinity-box'} width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={'Pasar Workspace a Unlimited'} primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            )}
          </>
        )}

        {/* User Plan */}
        <Divider />
        {Object.values(subscriptionPlans).find((subscriptionPlan) => subscriptionPlan.id === user.planId).name !==
          subscriptionPlans.free.name && (
          <MenuItem
            onClick={async () => {
              await alterUser('update-user-plan-free', user.id);
            }}
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon={'emojione-monotone:free-button'} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={'Pasar usuario a Free'} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {Object.values(subscriptionPlans).find((subscriptionPlan) => subscriptionPlan.id === user.planId).name !==
          subscriptionPlans.premium.name && (
          <MenuItem
            onClick={async () => {
              await alterUser('update-user-plan-premium', user.id);
            }}
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon={'mage:premier-pro'} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={'Pasar usuario a Pro'} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {Object.values(subscriptionPlans).find((subscriptionPlan) => subscriptionPlan.id === user.planId).name !==
          subscriptionPlans.unlimited.name && (
          <MenuItem
            onClick={async () => {
              await alterUser('update-user-plan-unlimited', user.id);
            }}
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon={'mdi:infinity-box'} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={'Pasar usuario a Unlimited'} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={async () => {
            await alterUser('delete', user.id);
          }}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon="eva:person-delete-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Eliminar usuario" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
