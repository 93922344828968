/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Typography } from '@mui/material';
import CreateNewUserForm from '../forms/CreateNewUserForm';

CreateNewUserFormDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default function CreateNewUserFormDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth={true} onClose={handleClose} open={open}>
      <Box p={2}>
        <DialogTitle
          sx={{
            p: 0,
            pb: 2,
          }}
        >
          <Typography color="primary" variant="h3">
            Creación de nuevo usuario
          </Typography>
          <Typography color="primary.light" variant="subtitle2">
            Si además quieres hacer a tu usuario ADMINISTRADOR recuerda que puedes realizar esta acción desde el menu
            individual de la tabla de usuarios.
          </Typography>
        </DialogTitle>
        <CreateNewUserForm />
      </Box>
    </Dialog>
  );
}
