import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box } from '@mui/material';
import { BaseOptionChart } from '../../../components/chart';

AppBalance.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppBalance({ title, subheader, chartLabels, chartData, ...other }) {
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill), gradient: { opacityFrom: 0, opacityTo: 0 } },
    labels: chartLabels,
    xaxis: { type: 'month' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            const val = other.isCost ? `${y.toFixed(2)} $` : `${y} ${other.symbol}`;
            return val;
          }
          return y;
        },
      },
      x: {
        formatter: (x) => {
          if (typeof x !== 'undefined') {
            const val = other.bottomTooltipValue
              ? `${other.bottomTooltipText}: ${other.bottomTooltipValue[x - 1].toFixed(2)} ${
                  other.bottomTooltipMeasure
                }`
              : null;
            return val;
          }
          return x;
        },
      },
    },
  });

  return (
    <Card
      sx={{
        bgcolor: (theme) => theme.palette.primary.light,
      }}
      {...other}
    >
      <CardHeader
        sx={{
          color: 'white',
        }}
        title={title}
        subheader={subheader}
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
