import { Box, Grid, Container, Typography, CircularProgress } from '@mui/material';
import Page from '../../components/Page';
import useAuthApiCall from '../../hooks/useAuthApiCall';
import { endpoints } from '../../config';
import Counter from './components/Counter';

export default function DashboardFunctionalites() {
  const { json: kpisRes } = useAuthApiCall({
    method: 'GET',
    endpoint: endpoints.kpisFunctionalites,
    path: '',
  });

  return (
    <Page title="Functionalities KPIs">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {!kpisRes && (
            <Box width="100%" height="80vh" alignItems="center" display="flex" justifyContent="center">
              <CircularProgress size="100px" />
            </Box>
          )}
          {kpisRes && (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                  🏠 Workspaces
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Counter number={kpisRes.workspaces.numberOfWorkspaces} subtitle="Workspaces" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Counter number={kpisRes.workspaces.averageMembers} subtitle="Av. Members" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Counter number={kpisRes.workspaces.totalMembers} subtitle="Total Members" />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                  🎫 Tags
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.tags.numberOfTags} subtitle="Tags" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.tags.totalMeetingsWithTags} subtitle="Meetings with tags" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.tags.usersWithTags} subtitle="Users with tags" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.tags.averageTagsPerUser} subtitle="Av. tags/user" />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                  📃 Meeting Notes
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.meetingNotes.numberOfNotes} subtitle="Total Notes" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.meetingNotes.meetingsWithNotes} subtitle="Meetings with notes" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.meetingNotes.usersWithNotes} subtitle="Users with notes" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Counter number={kpisRes.meetingNotes.averageNotesPerUser} subtitle="Av. notes/user" />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
