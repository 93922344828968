import { AppBalance } from '../../../sections/@dashboard/app';
import PropTypes from 'prop-types';

export default function RealUsersGraph({
  theme,
  activeUsersByMonth,
  // aboveAverageUsersByMonth,
  monthlabels,
  qualifiedUsersByMonth,
  realUsersByMonth,
}) {
  // TODO: Get the right number from api
  realUsersByMonth.usage.map((monthUsage) => {
    Number(parseFloat(monthUsage).toFixed(2));
  });

  return (
    <AppBalance
      title="Actividad de uso"
      subheader={`Activos último mes: ${activeUsersByMonth[activeUsersByMonth.length - 2]} / Cualificados último mes: ${
        qualifiedUsersByMonth[qualifiedUsersByMonth.length - 2]
      } / Reales último mes: ${realUsersByMonth.realUsers[realUsersByMonth.realUsers.length - 2]}`}
      chartLabels={monthlabels}
      symbol=""
      chartData={[
        {
          name: 'Usuarios reales',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.chart.red[0],
          data: realUsersByMonth.realUsers,
        },
        {
          name: 'Usuarios cualificados',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.chart.blue[0],
          data: qualifiedUsersByMonth,
        },
        {
          name: 'Usuarios activos',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.secondary.main,
          data: activeUsersByMonth,
        },
      ]}
      // TODO: MEDIA DE USO
      bottomTooltipValue={realUsersByMonth.churn}
      bottomTooltipText={'Churn'}
      bottomTooltipMeasure={'%'}
    />
  );
}

RealUsersGraph.propTypes = {
  theme: PropTypes.any,
  activeUsersByMonth: PropTypes.arrayOf(PropTypes.number),
  aboveAverageUsersByMonth: PropTypes.arrayOf(PropTypes.number),
  monthlabels: PropTypes.arrayOf(PropTypes.string),
  qualifiedUsersByMonth: PropTypes.arrayOf(PropTypes.number),
  realUsersByMonth: PropTypes.arrayOf(PropTypes.number),
};
