import { AppBalance } from '../../../sections/@dashboard/app';
import PropTypes from 'prop-types';

export default function MeetingsGraph({
  theme,
  dailyMeetings,
  weeklyMeetings,
  meetingsByMonth,
  // meetingsByMonthByDirectUpload,
  // meetingsByMonthbyExtension,
  growthPercentageByMonth,
  monthlabels,
}) {
  const createdMeetings = meetingsByMonth.map((currentMeetings, index) => currentMeetings - meetingsByMonth[index - 1]);
  return (
    <AppBalance
      title={`Meetings`}
      subheader={`Daily: ${dailyMeetings} / Weekly: ${weeklyMeetings}`}
      chartLabels={monthlabels}
      symbol=""
      chartData={[
        {
          name: 'Total',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.secondary.main,
          data: meetingsByMonth,
        },
        // {
        //   name: 'Total platform',
        //   type: 'area',
        //   fill: 'gradient',
        //   color: theme.palette.chart.violet[0],
        //   data: meetingsByMonthByDirectUpload,
        // },
        // {
        //   name: 'Total extension',
        //   type: 'area',
        //   fill: 'gradient',
        //   color: theme.palette.chart.green[0],
        //   data: meetingsByMonthbyExtension,
        // },
        {
          name: 'New',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.chart.blue[0],
          data: createdMeetings,
        },
      ]}
      bottomTooltipValue={growthPercentageByMonth}
      bottomTooltipText={'Growth'}
      bottomTooltipMeasure={'%'}
    />
  );
}

MeetingsGraph.propTypes = {
  theme: PropTypes.any,
  dailyMeetings: PropTypes.number,
  weeklyMeetings: PropTypes.number,
  meetingsByMonth: PropTypes.arrayOf(PropTypes.number),
  meetingsByMonthByDirectUpload: PropTypes.arrayOf(PropTypes.number),
  meetingsByMonthbyExtension: PropTypes.arrayOf(PropTypes.number),
  growthPercentageByMonth: PropTypes.arrayOf(PropTypes.number),
  monthlabels: PropTypes.arrayOf(PropTypes.string),
};
