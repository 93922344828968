import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import TableUser from './pages/TableUser';
import Login from './pages/Login';
import DashboardApp from './pages/dashboardApp/DashboardApp';
import DashboardFunctionalites from './pages/dashboardApp/DashboardFunctionalities';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = [
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <TableUser /> },
        { path: 'functionalities', element: <DashboardFunctionalites /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        // { path: 'register', element: <Register /> },
        // { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  return useRoutes([...routes]);
}
