export const subscriptionPlans = {
  free: {
    id: 1,
    name: 'FREE',
    label: 'Gratuito',
    chipColor: 'primary',
  },
  premium: {
    id: process.env.NODE_ENV.includes('production') ? 3 : 6,
    name: 'PREMIUM',
    label: 'Pro',
    chipColor: 'success',
  },
  unlimited: {
    id: 2,
    name: 'UNLIMITED',
    label: 'Unlimited',
    chipColor: 'warning',
  },
};
