import { AppWidgetSummary } from '../../../sections/@dashboard/app';
import PropTypes from 'prop-types';

export default function Counter({ number, subtitle }) {
  return (
    <AppWidgetSummary
      sx={{
        height: '100%',
      }}
      color="secondary"
      title={subtitle}
      total={number}
      icon={'eva:bar-chart-2-outline'}
    />
  );
}

Counter.propTypes = {
  theme: PropTypes.any,
  number: PropTypes.number,
  subtitle: PropTypes.string,
};
