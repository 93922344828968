import { AppBalance } from '../../../sections/@dashboard/app';
import PropTypes from 'prop-types';

export default function UsersGraph({
  theme,
  monthlyUsers,
  usersByMonth,
  growthPercentageByMonth,
  monthlabels,
  dailyAverageByMonth,
  dailyNewUsers,
}) {
  const singups = usersByMonth.map((currentUsers, index) => currentUsers - usersByMonth[index - 1]);
  return (
    <AppBalance
      title={`Users`}
      subheader={`Daily: ${dailyNewUsers} / Monthly users: ${monthlyUsers} `}
      chartLabels={monthlabels}
      symbol=""
      chartData={[
        {
          name: 'Total',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.secondary.main,
          data: usersByMonth,
        },
        {
          name: 'New',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.chart.blue[0],
          data: singups,
        },
        {
          name: 'Daily Av.',
          type: 'area',
          fill: 'gradient',
          color: theme.palette.chart.violet[0],
          data: dailyAverageByMonth,
        },
      ]}
      bottomTooltipValue={growthPercentageByMonth}
      bottomTooltipText={'Growth'}
      bottomTooltipMeasure={'%'}
    />
  );
}

UsersGraph.propTypes = {
  theme: PropTypes.any,
  monthlyUsers: PropTypes.number,
  usersByMonth: PropTypes.arrayOf(PropTypes.number),
  growthPercentageByMonth: PropTypes.arrayOf(PropTypes.number),
  monthlabels: PropTypes.arrayOf(PropTypes.string),
  dailyAverageByMonth: PropTypes.arrayOf(PropTypes.number),
  dailyNewUsers: PropTypes.number,
};
