export const serverUrl = process.env.NODE_ENV.includes('production')
  ? 'https://api.voicit.com'
  : 'https://app.dev.voicit.syndeno.io';
export const baseUrl = process.env.REACT_APP_DOMAIN_URL;
const adminBasePath = '/admin-api';
export const endpoints = {
  auth: `/user-api/auth`,
  users: `${adminBasePath}/users`,
  kpis: `${adminBasePath}/kpis`,
  kpisFunctionalites: `${adminBasePath}/kpis/secondary`,
  workspaces: `${adminBasePath}/workspaces`,
};
export const authHeader = 'x-voicit-auth';
