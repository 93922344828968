import { configureStore } from '@reduxjs/toolkit';
import ReduxLogger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import rootReducer from './reducers/index';

// Persist object config
const persistConfig = {
  key: 'counter', // ID of the persist object
  storage,
  whitelist: ['auth'], // only auth will be persisted
};

// Reducers combination
const reducers = rootReducer;
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(ReduxLogger),
});
