import * as Yup from 'yup';
import { Fragment, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { serverUrl } from '../../config';
import Typography from '@mui/material/Typography';
import { generatePassword } from 'src/utils/password';

export default function CreateNewUserForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const FormSchema = Yup.object().shape({
    givenName: Yup.string().required('Introduce un nombre de usuario'),
    email: Yup.string().email('El email debe ser válido').required('El email es un campo obligatorio'),
    phoneNumber: Yup.string().required('El teléfono es un campo obligatorio'),
  });

  const defaultValues = {
    email: '',
    password: '',
    givenName: '',
    phoneNumber: '',
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('email', values.email);
    formData.append('phoneNumber', values.phoneNumber);
    formData.append(
      'password',
      values && values.password.length > 1 && !generatedPassword ? values.password : generatePassword()
    );
    formData.append('givenName', values.givenName);
    const userData = Object.fromEntries(formData);

    const res = await axios.post(`${serverUrl}/user-api/auth/signup`, userData).catch((e) => {
      if (e && e.response && e.response.data) {
        setError(e.response.data.message);
        setSuccess(null);
        return;
      }
      setError('El servidor no responde');
      setSuccess(null);
      return;
    });

    if (res.status === 200) {
      setSuccess(userData);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="givenName" label="Nombre completo" />
        <RHFTextField name="email" label="Email" />
        <RHFTextField name="phoneNumber" label="Número de teléfono ej.: +34 685344764" />
        <FormControlLabel
          label="Autogenerar contraseña"
          control={
            <Checkbox onChange={setGeneratedPassword.bind(null, !generatedPassword)} checked={generatedPassword} />
          }
        />
        <Typography variant="subtitle2">
          En cualquier caso, si dejas el campo de contraseña en blanco esta será autogenerada
        </Typography>
        {!generatedPassword && (
          <RHFTextField
            name="password"
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      <LoadingButton
        sx={{ mt: 3 }}
        fullWidth
        size="large"
        type="submit"
        color="primary"
        variant="contained"
        loading={isSubmitting}
      >
        <Typography color="secondary">Enviar</Typography>
      </LoadingButton>
      <Typography fontWeight={'bold'} color="red" align="center" sx={{ mt: 3 }}>
        {error}
      </Typography>
      <Typography color="black" sx={{ mt: 3 }}>
        {success && (
          <Fragment>
            <span style={{ marginBottom: 2 }}>El usuario ha sido creado con los siguientes datos</span>
            <pre>
              <code>{JSON.stringify(success, null, 2)}</code>
            </pre>
          </Fragment>
        )}
      </Typography>
    </FormProvider>
  );
}
