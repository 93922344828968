/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import { setAuthUser, setAuthToken } from 'src/redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { endpoints } from '../../config';
import { authApiCall } from '../../services/http';
import { Box, Grid, Typography } from '@mui/material';
import { AppWidgetSummary, AppBalance, AppCurrentVisits } from '../../sections/@dashboard/app';
import { fSecondsToHours } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';

UserMetricsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  userId: PropTypes.number,
};

function UserMetricsDialog(props) {
  const { onClose, open, userId } = props;
  const [userData, setUserData] = React.useState(null);
  const authToken = useSelector((state) => state.auth.authToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const authApiCallHookProps = {
    navigate,
    dispatch,
    setAuthUser,
    setAuthToken,
    authToken,
    redirectRoute: null,
  };

  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    (async () => {
      if (userId !== -1) {
        const result = await authApiCall({
          method: 'GET',
          endpoint: endpoints.kpis,
          path: `/${userId}`,
          ...authApiCallHookProps,
        });
        setUserData(result.data);
      }
    })();
  }, [userId]);

  return (
    <Dialog fullWidth={true} onClose={handleClose} open={open}>
      {userData && (
        <Box p={2}>
          <DialogTitle align="left">
            Métricas para{' '}
            <Typography color="primary" variant="body2">
              {userData.userInfo.email}
            </Typography>
          </DialogTitle>
          <Grid pt={2} spacing={1} container>
            <Grid item xs={12} sm={12} md={12}>
              <AppWidgetSummary
                py={2}
                color="secondary"
                title="Reuniones totales"
                total={userData.meetings.total}
                icon={'eva:message-circle-outline'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AppWidgetSummary
                py={2}
                color="secondary"
                title="Reuniones por subida directa"
                total={userData.meetings.totalBySource.upload}
                icon={'eva:cloud-upload-outline'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AppWidgetSummary
                py={2}
                color="secondary"
                title="Reuniones a través de la extensión"
                total={userData.meetings.totalBySource.extension}
                icon={'eva:browser-outline'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AppWidgetSummary
                py={2}
                fm={fCurrency}
                color="secondary"
                title="Coste total generado en resúmenes"
                total={userData.summaries.totalCost}
                icon={'eva:pricetags-outline'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AppWidgetSummary
                py={2}
                color="secondary"
                fm={fSecondsToHours}
                title="Tiempo de transcripción total"
                total={userData.transcriptions.totalTime}
                icon={'eva:file-text-outline'}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <AppWidgetSummary
                py={2}
                color="secondary"
                fm={fSecondsToHours}
                title="Tiempo medio de reunión"
                total={userData.meetings.averageDuration}
                icon={'eva:clock-outline'}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <AppBalance
                title="Gastos y número de resúmenes"
                chartLabels={userData.monthlabels}
                chartData={[
                  {
                    name: 'Costo',
                    type: 'area',
                    fill: 'gradient',
                    color: '#F24D06',
                    data: userData.summaries.costsByMonth.data,
                  },
                  {
                    name: 'Resúmenes',
                    type: 'area',
                    fill: 'gradient',
                    color: 'rgb(130, 106, 249)',
                    data: userData.summaries.numberByMonth.data,
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <AppCurrentVisits
                title="Reuniones por plataforma"
                chartData={userData.meetings.totalByPlatform}
                chartColors={[
                  theme.palette.chart.red[0],
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                  theme.palette.chart.green[0],
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Dialog>
  );
}

export default UserMetricsDialog;
