import axios from 'axios';
import { authHeader, serverUrl } from '../config';

export const authApiCall = async ({
  method,
  endpoint,
  authToken,
  contentType,
  path,
  data,
  redirectRoute,
  navigate,
  dispatch,
  setAuthUser,
  setAuthToken,
  options,
}) =>
  axios(`${serverUrl}${endpoint}${path}`, {
    method,
    data,
    headers: {
      [authHeader]: authToken,
      /* eslint-disable */
      'Content-type': contentType ? contentType : 'application/json',
    },
    options,
  }).catch((error) => {
    if (error.response.status === 401 && error.response.data && error.response.data.redirectUri) {
      /* eslint-disable */
      const redirectUri = redirectRoute ? redirectRoute : error.response.data.redirectUri;
      dispatch(setAuthUser(null));
      dispatch(setAuthToken(null));
      navigate({
        pathname: redirectUri,
        search: window.location.search,
        hash: window.location.hash,
      });
      return;
    }
    throw new Error(error);
  });
