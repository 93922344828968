import { AppCurrentVisits } from '../../../sections/@dashboard/app';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useState } from 'react';

export default function UseCasePieChart({ theme, useCases }) {
  const [lastMonth, setLastMonth] = useState(false);
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={lastMonth} onChange={() => setLastMonth(!lastMonth)} />}
          label={lastMonth ? 'Último mes' : 'Total'}
        />
      </FormGroup>
      <AppCurrentVisits
        title="Use Case"
        isCost={false}
        showLabels={true}
        chartData={lastMonth ? useCases.lastMonth : useCases.total}
        chartColors={[
          theme.palette.chart.red[0],
          theme.palette.chart.blue[0],
          theme.palette.chart.violet[0],
          theme.palette.chart.green[0],
          theme.palette.chart.yellow[0],
          theme.palette.chart.violet[3],
        ]}
      />
    </>
  );
}

UseCasePieChart.propTypes = {
  theme: PropTypes.any,
  useCases: PropTypes.arrayOf(PropTypes.number),
};
