import { useTheme } from '@mui/material/styles';
import { Box, Grid, Container, Typography, CircularProgress } from '@mui/material';
import Page from '../../components/Page';
import useAuthApiCall from '../../hooks/useAuthApiCall';
import { endpoints } from '../../config';
import UsersGraph from './components/UsersGraph';
import MeetingsGraph from './components/MeetingsGraph';
import MeetingsByPlatformPieChart from './components/MeetingsByPlatformPieChart';
// import AverageMeetingTime from './components/AverageMeetingTime';
import RealUsersGraph from './components/RealUsersGraph';
import ActiveUsers from './components/ActiveUsers';
import Counter from './components/Counter';
import UseCasePieChart from './components/UseCasePieChart';

export default function DashboardApp() {
  const theme = useTheme();
  const { json: kpisRes } = useAuthApiCall({
    method: 'GET',
    endpoint: endpoints.kpis,
    path: '',
  });

  return (
    <Page title="Panel de administrador">
      <Container maxWidth="xl">
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            📃 Meetings
          </Typography>
        </Grid>

        <Grid container spacing={3}>
          {!kpisRes && (
            <Box width="100%" height="80vh" alignItems="center" display="flex" justifyContent="center">
              <CircularProgress size="100px" />
            </Box>
          )}
          {kpisRes && (
            <>
              <Grid item xs={12} sm={4}>
                <Counter
                  number={
                    kpisRes.withoutAdmins.meetings.byMonth.total.data[
                      kpisRes.withoutAdmins.meetings.byMonth.total.data.length - 1
                    ] -
                    kpisRes.withoutAdmins.meetings.byMonth.total.data[
                      kpisRes.withoutAdmins.meetings.byMonth.total.data.length - 2
                    ]
                  }
                  subtitle="Monthly Meetings"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <UseCasePieChart theme={theme} useCases={kpisRes.withoutAdmins.users.useCases} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MeetingsByPlatformPieChart
                  theme={theme}
                  totalMeetingsByPlatform={kpisRes.withoutAdmins.meetings.totalByPlatform}
                />
              </Grid>
              <Grid item xs={12}>
                <MeetingsGraph
                  theme={theme}
                  monthlabels={kpisRes.monthlabels}
                  dailyMeetings={kpisRes.common.meetings.currentDayNewMeetingsAndDAU.dailyMeetings}
                  weeklyMeetings={kpisRes.common.meetings.currentWeekNewMeetings}
                  lastMonthGrowth={kpisRes.withoutAdmins.meetings.growthPercentage.toFixed(2)}
                  meetingsByMonth={kpisRes.withoutAdmins.meetings.byMonth.total.data}
                  meetingsByMonthByDirectUpload={kpisRes.withoutAdmins.meetings.byMonth.byDirectUpload.data}
                  meetingsByMonthbyExtension={kpisRes.withoutAdmins.meetings.byMonth.byExtension.data}
                  growthPercentageByMonth={kpisRes.withoutAdmins.meetings.byMonth.growth.data}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                  🎪 Activity
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ActiveUsers
                  activeUsers={kpisRes.common.meetings.currentDayNewMeetingsAndDAU.dailyActiveUsers}
                  type="Daily"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ActiveUsers activeUsers={kpisRes.withoutAdmins.users.activeUsers.byWeek} type="Weekly" />
              </Grid>
              <Grid item xs={12}>
                <RealUsersGraph
                  theme={theme}
                  monthlabels={kpisRes.monthlabels}
                  realUsersByMonth={kpisRes.withoutAdmins.users.realUsersByMonth}
                  activeUsersByMonth={kpisRes.withoutAdmins.users.activeUsers.byMonth}
                  aboveAverageUsersByMonth={kpisRes.withoutAdmins.users.aboveAverageUsers.byMonth}
                  qualifiedUsersByMonth={kpisRes.withoutAdmins.users.qualifiedUsersByMonth}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                  🎎 Users
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <UsersGraph
                  theme={theme}
                  monthlabels={kpisRes.monthlabels}
                  monthlyUsers={
                    kpisRes.withoutAdmins.users.byMonth.usersByMonth.data[
                      kpisRes.withoutAdmins.users.byMonth.usersByMonth.data.length - 1
                    ] -
                    kpisRes.withoutAdmins.users.byMonth.usersByMonth.data[
                      kpisRes.withoutAdmins.users.byMonth.usersByMonth.data.length - 2
                    ]
                  }
                  usersByMonth={kpisRes.withoutAdmins.users.byMonth.usersByMonth.data}
                  growthPercentageByMonth={kpisRes.withoutAdmins.users.byMonth.usersGrowthByMonth.data}
                  dailyAverageByMonth={kpisRes.withoutAdmins.users.byMonth.dailyAverageUsersByMonth.data.map((d) => {
                    return d ? d.toFixed(2) : 0;
                  })}
                  dailyNewUsers={kpisRes.common.users.currentDayNewUsers}
                />
              </Grid>

              {/* <Grid item xs={12} md={9} lg={9}>
                <CostsGraph
                  monthlabels={kpisRes.monthlabels}
                  theme={theme}
                  transcriptionCostsByMonth={kpisRes.common.transcriptions.costsByMonth.data}
                  summaryCostsByMonth={kpisRes.common.summaries.costsByMonth.data}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <CostsPieChart
                  theme={theme}
                  totalTranscriptionCost={parseFloat(kpisRes.common.transcriptions.totalCost.toFixed(2))}
                  totalSummaryCost={parseFloat(kpisRes.common.summaries.totalCost.toFixed(2))}
                />
              </Grid> */}
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
