/* eslint-disable */
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Slider,
  Switch,
  FormControlLabel,
  FormLabel,
  FormControl,
  Tooltip,
  FormGroup,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken, setAuthUser } from '../redux/reducers/authSlice';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user/index';
import { authApiCall } from '../services/http';
import { endpoints } from '../config';
import UserMetricsDialog from '../layouts/dialogs/UserMetricsDialog';
import Iconify from 'src/components/Iconify';
import CreateNewUserFormDialog from 'src/layouts/dialogs/CreateNewUserFormDialog';
import copyToClipboard from 'src/utils/copyToClipboard';
import { subscriptionPlans } from 'src/helpers/userPlans';

const TABLE_HEAD = [
  { id: 'ID', label: 'ID', alignRight: false },
  { id: 'createdAt', label: 'Signup', alignRight: false },
  { id: 'weeklyActivity', label: '7 days', alignRight: false },
  { id: 'meetingCount', label: 'This month', alignRight: false },
  { id: 'lastMonthActivity', label: 'Past month', alignRight: false },
  { id: 'totalMeetings', label: 'Total', alignRight: false },
  { id: 'givenName', label: 'Name', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: 'workspace', label: 'Workspace', alignRight: false },
  { id: 'plan', label: 'Plan', alignRight: false },
  { id: 'use', label: 'Use', alignRight: false },
  { id: '' },
];

const colorMap = {
  sales: 'error',
  clients: 'success',
  hiring: 'info',
  training: 'primary',
  team: 'warning',
  other: 'default',
  null: 'default',
};

export default function TableUser() {
  const [userList, setuserList] = useState([]);
  const [page, setPage] = useState(0);
  const [order] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userMetricsDialogOpen, setUserMetricsDialogOpen] = useState(false);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [userMetricsCurrentId, setUserMetricsCurrentId] = useState(-1);
  const [meetingsPerMonthSearchValue, setMeetingPerMonthSearchValue] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const [filterByMonth, setFilterByMonth] = useState(true);
  const [filterByHR, setFilterByHR] = useState(false);
  const authToken = useSelector((state) => state.auth.authToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getColor = (useCase) => colorMap[useCase] || 'default';

  const authApiCallHookProps = {
    navigate,
    dispatch,
    setAuthUser,
    setAuthToken,
    authToken,
    redirectRoute: null,
  };

  useEffect(() => {
    (async () => {
      await handlePagination(page, rowsPerPage);
    })();
  }, [page, rowsPerPage]);

  const handleSearch = async ({ reset }) => {
    setIsLoading(true);
    let data = {};
    if (filterByHR) {
      data.useCase = 'hiring';
      data.filterByMonth = true;
    }

    if ((filterName !== '' || meetingsPerMonthSearchValue !== 0 || filterByHR) && !reset) {
      const result = await authApiCall({
        method: 'POST',
        endpoint: endpoints.users,
        path: `/search`,
        ...authApiCallHookProps,
        data: {
          meetingNumber: meetingsPerMonthSearchValue,
          emailUsernameOrGivenNameOrId: filterName,
          filterByMonth: true,
          ...data,
        },
      });
      const { data: json } = result;

      setuserList([...json.data]);
      setRowCount(json.count);
    } else if (reset) {
      setFilterName('');
      setMeetingPerMonthSearchValue(0);
      await handlePagination(page, rowsPerPage);
    }
    setIsLoading(false);
  };

  const handlePagination = async (page, pageSize) => {
    const result = await authApiCall({
      method: 'GET',
      endpoint: endpoints.users,
      path: `/paginated/${page}/${pageSize}`,
      ...authApiCallHookProps,
    });
    setuserList([...result.data.users.data]);
    setRowCount(result.data.users.count);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByEmailUsernameOrGivenName = async (event) => {
    setFilterName(event.target.value);
  };

  const handleMenuAction = (action, userId) => {
    const index = userList.findIndex((u) => u.id === userId);
    let alteredUserList = userList;

    switch (action) {
      case 'make-admin':
        alteredUserList[index].role = 'ADMIN';
        break;
      case 'undo-make-admin':
        alteredUserList[index].role = 'USER';
        break;
      case 'delete':
        alteredUserList = alteredUserList.filter((u) => u.id !== userId);
        break;
      case 'show-metrics':
        setUserMetricsDialogOpen(true);
        setUserMetricsCurrentId(userId);
        break;
      case 'update-user-plan-free':
        alteredUserList[index] = {
          ...alteredUserList[index],
          planId: subscriptionPlans.free.id,
        };
        break;
      case 'update-user-plan-premium':
        alteredUserList[index] = {
          ...alteredUserList[index],
          planId: subscriptionPlans.premium.id,
        };
        break;
      case 'update-user-plan-unlimited':
        alteredUserList[index] = {
          ...alteredUserList[index],
          planId: subscriptionPlans.unlimited.id,
        };
        break;
      case 'update-workspace-plan-free':
        alteredUserList = alteredUserList.map((u) => {
          if (u.workspace && u.workspace.id === userId) {
            const planId = subscriptionPlans.free.id;
            return {
              ...u,
              workspace: {
                ...u.workspace,
                planId,
              },
            };
          }
          return u;
        });
        break;
      case 'update-workspace-plan-premium':
        alteredUserList = alteredUserList.map((u) => {
          if (u.workspace && u.workspace.id === userId) {
            const planId = subscriptionPlans.premium.id;
            return {
              ...u,
              workspace: {
                ...u.workspace,
                planId,
              },
            };
          }
          return u;
        });
        break;
      case 'update-workspace-plan-unlimited':
        alteredUserList = alteredUserList.map((u) => {
          if (u.workspace && u.workspace.id === userId) {
            const planId = subscriptionPlans.unlimited.id;
            return {
              ...u,
              workspace: {
                ...u.workspace,
                planId,
              },
            };
          }
          return u;
        });
        break;
      default:
    }
    setuserList([...alteredUserList]);
    setRowCount(rowCount - 1);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowCount) : 0;

  return (
    <Page title="Usuarios">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuarios
          </Typography>
        </Stack>

        <Stack mb={2}>
          <Button
            style={{ width: '200px' }}
            startIcon={<Iconify icon="eva:plus-outline" />}
            sx={{
              mb: 2,
            }}
            onClick={setNewUserDialogOpen.bind(null, true)}
            variant="contained"
            color="primary"
          >
            <Typography>Nuevo</Typography>
          </Button>

          <FormControl>
            <Slider
              onChange={(e) => {
                setMeetingPerMonthSearchValue(e.target.value);
              }}
              valueLabelDisplay="auto"
              value={meetingsPerMonthSearchValue}
              step={1}
              marks
              min={0}
              max={20}
            />
          </FormControl>
          <FormControlLabel
            control={<Switch checked={filterByHR} onChange={() => setFilterByHR(!filterByHR)} />}
            label="Filtrar HR mes actual"
          />
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByEmailUsernameOrGivenName}
            handleSearch={handleSearch}
            isLoading={isLoading}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rowCount}
                  numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {userList.map((row) => {
                    const {
                      id,
                      givenName,
                      planId,
                      email,
                      role,
                      Statistics,
                      workspace,
                      totalMeetings,
                      createdAt,
                      phoneNumber,
                      weeklyActivity,
                      lastMonthActivity,
                      useCase,
                      monthlyActivity,
                    } = row;
                    const isItemSelected = selected.indexOf(givenName) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <Tooltip title="Click para copiar">
                          <TableCell
                            align="left"
                            onClick={() => copyToClipboard(id)}
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                          >
                            {id}
                          </TableCell>
                        </Tooltip>
                        <TableCell align="left">{new Date(createdAt).toLocaleDateString()}</TableCell>
                        <TableCell align="left">{weeklyActivity}</TableCell>
                        <TableCell align="left">{monthlyActivity}</TableCell>
                        <TableCell align="left">{lastMonthActivity}</TableCell>
                        <TableCell align="left">{totalMeetings}</TableCell>
                        <TableCell align="left">{givenName || ' '}</TableCell>
                        <Tooltip title="Click para copiar">
                          <TableCell
                            align="left"
                            onClick={() => copyToClipboard(email)}
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                          >
                            {email} {phoneNumber}
                          </TableCell>
                        </Tooltip>

                        <TableCell align="left">
                          {Object.keys(workspace).length > 0 ? (
                            <Label
                              variant="ghost"
                              color={
                                Object.values(subscriptionPlans).find(
                                  (subscriptionPlan) => subscriptionPlan.id === workspace.planId
                                ).chipColor
                              }
                            >
                              {workspace.name}
                            </Label>
                          ) : (
                            <Label variant="ghost" color="primary">
                              -
                            </Label>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {planId && (
                            <Label
                              variant="outlined"
                              color={
                                Object.values(subscriptionPlans).find(
                                  (subscriptionPlan) => subscriptionPlan.id === planId
                                ).chipColor
                              }
                            >
                              {
                                Object.values(subscriptionPlans).find(
                                  (subscriptionPlan) => subscriptionPlan.id === planId
                                ).label
                              }
                            </Label>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {role && (
                            <Label variant="ghost" color={getColor(useCase)}>
                              {role === 'ADMIN' ? 'Ad-' : ''}
                              {useCase ? sentenceCase(useCase) : '?'}
                            </Label>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <UserMoreMenu onMenuAction={handleMenuAction} user={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {filterName === '' && meetingsPerMonthSearchValue === 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
      <UserMetricsDialog
        userId={userMetricsCurrentId}
        open={userMetricsDialogOpen}
        onClose={setUserMetricsDialogOpen.bind(null, false)}
      />
      <CreateNewUserFormDialog open={newUserDialogOpen} onClose={setNewUserDialogOpen.bind(null, false)} />
    </Page>
  );
}
