import { AppWidgetSummary } from '../../../sections/@dashboard/app';
import PropTypes from 'prop-types';

export default function ActiveUsers({ activeUsers, type }) {
  return (
    <AppWidgetSummary
      sx={{
        height: '100%',
      }}
      color="secondary"
      title={`${type} Active Users`}
      total={activeUsers}
      icon={'eva:bar-chart-2-outline'}
    />
  );
}

ActiveUsers.propTypes = {
  type: PropTypes.string,
  activeUsers: PropTypes.number,
};
