import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { authHeader, serverUrl } from '../config';
import { setAuthToken, setAuthUser } from '../redux/reducers/authSlice';

export default function useAuthApiCall({ method, endpoint, path, data, redirectRoute, options }) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [statusText, setStatusText] = useState(null);
  const [json, setJSON] = useState(null);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.authToken);
  const dispatch = useDispatch();

  const call = async () => {
    try {
      const response = await axios(`${serverUrl}${endpoint}${path}`, {
        method,
        data,
        headers: {
          [authHeader]: token,
        },
        options,
      });
      setJSON(response.data);
      setStatus(response.status);
      setStatusText(response.statusText);
    } catch (e) {
      if (e.response.status === 401 && e.response.data && e.response.data.redirectUri) {
        /* eslint-disable */
        const redirectUri = redirectRoute ? redirectRoute : e.response.data.redirectUri;
        dispatch(setAuthUser(null));
        dispatch(setAuthToken(null));
        navigate({
          pathname: redirectUri,
          search: window.location.search,
          hash: window.location.hash,
        });
      }
      setError(e);
      setStatus(e.response.status);
      setStatusText(e.response.statusText);
    }
  };

  useEffect(() => {
    (async () => {
      await call();
    })();
  }, []);

  return { json, status, statusText, error };
}
