import { AppCurrentVisits } from '../../../sections/@dashboard/app';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

export default function MeetingsByPlatformPieChart({ theme, totalMeetingsByPlatform }) {
  const [lastMonth, setLastMonth] = useState(false);

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={lastMonth} onChange={() => setLastMonth(!lastMonth)} />}
          label={lastMonth ? 'Último mes' : 'Total'}
        />
      </FormGroup>
      <AppCurrentVisits
        title="Reuniones por plataforma"
        isCost={false}
        showLabels={true}
        chartData={lastMonth ? totalMeetingsByPlatform.lastMonth : totalMeetingsByPlatform.total}
        chartColors={[
          theme.palette.chart.red[0],
          theme.palette.chart.blue[0],
          theme.palette.chart.violet[0],
          theme.palette.chart.green[0],
        ]}
      />
    </>
  );
}

MeetingsByPlatformPieChart.propTypes = {
  theme: PropTypes.any,
  totalMeetingsByPlatform: PropTypes.arrayOf(PropTypes.number),
};
