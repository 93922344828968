import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { useEffect } from 'react';
import useAuthApiCall from './hooks/useAuthApiCall';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();
  const { json: authUser, error: authError } = useAuthApiCall({
    method: 'GET',
    endpoint: '/user-api/',
    path: '',
  });

  useEffect(() => {
    if (!authUser || authError) {
      navigate('/login');
      return;
    }

    navigate('/dashboard/app');
  }, [authUser, authError]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
