import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Button, Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleSearch: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, handleSearch, isLoading }) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch({ reset: false });
    }
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            onKeyDown={handleKeyDown}
            placeholder="Busca un usuario por nombre, id o email..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <Button sx={{ ml: 2 }} variant="contained" onClick={() => handleSearch({ reset: false })}>
            {isLoading ? '...' : 'Buscar'}
          </Button>
          <Button sx={{ ml: 2 }} onClick={() => handleSearch({ reset: true })}>
            Limpiar
          </Button>
        </>
      )}

      {
        numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        ) : null
        //   (
        //     <Tooltip title="Filter list">
        //       <IconButton>
        //         <Iconify icon="ic:round-filter-list" />
        //       </IconButton>
        //     </Tooltip>
        //   )
      }
    </RootStyle>
  );
}
